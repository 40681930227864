import React from 'react';
import '../styles/HeroPage.css';
import { Button } from 'primereact/button';
import HeroImage from '../assets/heroImage.png';

const HeroPage = () => {
  return (
    <section
      className='hero__page__section'
      data-aos='fade-up'
      data-aos-duration='1000'
    >
      <div className='sub__section w-11 md:w-11 lg:w-11 xl:w-10 mx-auto'>
        <div className='grid'>
          <div className='col-12 sm:col-12 md:col-12 lg:col-6 xl:col-7'>
            <div className='hero__title'>
              <h3 className=''>
                {' '}
                Snipe Tokens Faster on Ethereum, Ton, Solana and Base With
                TapTrader, The Fatest Telegram Trading bot{' '}
              </h3>

              <p className='light-text -mt-3'>
                {' '}
                Experience One Tap trade execution, Accurate and secure
                portfolio management and Unparalled Results{' '}
              </p>
            </div>

            <div className='grid mt-5'>
              <div className='col-12 sm:col-12 md:col-6 lg:col-5 xl:col-4'>
                <Button
                  label='@TheTaptraderBot'
                  icon='pi pi-telegram '
                  className='w-full ___btn ___p-button '
                  iconPos='right'
                  onClick={() =>
                    window.open('https://t.me/TheTapTraderBot', '_blank')
                  }
                />
              </div>

              <div className='col-12 sm:col-12 md:col-6 lg:col-5 xl:col-4'>
                <Button
                  label='White Paper'
                  onClick={() =>
                    window.open('https://t.me/TheTapTraderBot', '_blank')
                  }
                  iconPos='right'
                  icon='pi pi-book'
                  className='w-full ___p-button  '
                />
              </div>
            </div>
          </div>
          <div className='col-12 sm:col-12 md:col-12 lg:col-6 lg:mt-4 xl:col-5'>
            <div className='hero___image'>
              <img src={HeroImage} loading='lazy' alt='HeroImage' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroPage;
