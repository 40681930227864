import React from 'react';
import '../styles/AppFooter.css';
import FooterLogo from '../assets/tplogo.png';
import { Button } from 'primereact/button';

const AppFooter = () => {
  return (
    <section
      className=' footer__section'
      data-aos='fade-down'
      data-aos-duration='1000'
    >
      <div className='grid w-11   md:w-10 lg:w-10 mx-auto my-4'>
        <div className='col-12 md:col-6 lg:col-3 xl:col-3  col__style'>
          <div className='footer__image'>
            <img
              src={FooterLogo}
              alt=' footer logo'
              className='foote__logo__style'
            />
          </div>
          <div className='pragraph'>
            <p>
              TapTrader is a fast Telegram trading bot for Ethereum, Solana, and
              Base, offering instant trades, low fees, and security.
            </p>
          </div>
          <div className='cion'>
            <i
              className='pi pi-telegram primary'
              style={{ fontSize: '1.5rem' }}
            ></i>
            <i
              className='pi pi-twitter mx-2'
              style={{ fontSize: '1.5rem' }}
            ></i>
            <i className='pi pi-discord' style={{ fontSize: '1.5rem' }}></i>
            <i
              className='pi pi-whatsapp ml-2'
              style={{ fontSize: '1.5rem' }}
            ></i>
          </div>
        </div>

        <div className='col-12 md:col-6 lg:col-3 xl:col-3   col__style'>
          <div className='div'>
            <h2 className='footer__title__style'>Tools & References</h2>
          </div>
          <ul className='list-none -ml-5'>
            {/* <li>
              <a href='/' className='a__style'>
                Asterizm Scanner{' '}
              </a>
            </li> */}
            <li>
              <a href='/' className='a__style'>
                Docs{' '}
              </a>
            </li>
            {/* <li>
              <a href='/' className='a__style'>
                White paper{' '}
              </a>
            </li> */}
            <li>
              <a href='/' className='a__style'>
                GitBook{' '}
              </a>
            </li>
          </ul>
        </div>

        <div className='col-12 md:col-6 lg:col-3 xl:col-3 col__style'>
          <div className='div'>
            <h2 className='footer__title__style'>Community</h2>
          </div>
          <ul className='list-none -ml-5'>
            {/* <li>
              <a href='/' className='a__style'>
                Media Kit{' '}
              </a>
            </li> */}

            <li>
              <a href='/' className='a__style'>
                X{' '}
              </a>
            </li>
            <li>
              <a href='/' className='a__style'>
                Medium{' '}
              </a>
            </li>
            <li>
              <a href='/' className='a__style'>
                Telegram{' '}
              </a>
            </li>

            <li>
              <a href='/' className='a__style'>
                Discord{' '}
              </a>
            </li>
          </ul>
        </div>
        <div className='col-12 md:col-6 lg:col-3 xl:col-3 '>
          <div className='div'>
            <h2 className='footer__title__style'>Careers</h2>
          </div>

          <div className='pragraph'>
            <p>We are Hiring</p>
          </div>

          <Button
            label='Join Us'
            className=''
            size='small'
            onClick={() =>
              window.open(process.env.REACT_APP_FORM_URL, '_blank')
            }
          />
        </div>
        <div className='col-12 booter__footer'>
          <p>
            &copy;{' '}
            <span className='footer__current-year'>
              {new Date().getFullYear()}
            </span>{' '}
            TapTrader. All rights reserved
          </p>
        </div>
      </div>
    </section>
  );
};

export default AppFooter;
