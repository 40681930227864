import React from 'react';
import { Button } from 'primereact/button';
import '../styles/JoinTapTrader.css';

const JoinTapTrader = () => {
  return (
    <div
      className='JoinTapTrader__section'
      data-aos='fade-down'
      data-aos-duration='1000'
    >
      <section className='w-11  md:w-8 lg:w-7 xl:w-5 mx-auto  '>
        <div className='JoinTapTrader__card text-center'>
          <h3>Join TapTrader Today</h3>
          <p className=''>Get started with $10K free volume.</p>
          <div className=' flex justify-content-center'>
            <Button
              label='START TRADING '
              className='button____style border-none'
              onClick={() =>
                window.open('https://t.me/TheTapTraderBot?start', '_blank')
              }
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default JoinTapTrader;
