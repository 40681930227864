import React from 'react';
import { Carousel } from 'primereact/carousel';
import '../styles/ChainsSupported.css';
import TonLogo from '../assets/toncoin-ton-logo.png';
import BaseLogo from '../assets/baslogo.png';
import SolanaLogo from '../assets/Solana_logo.png'
import EthLogo from '../assets/ethereum.png'

const ChainsSupported = () => {
  const products = [
    {
      name: ' ChainsSupportedImage',
      image: TonLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: BaseLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: SolanaLogo,
    },

    {
      name: ' ChainsSupportedImage',
      image: EthLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: TonLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: BaseLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: SolanaLogo,
    },

    {
      name: ' ChainsSupportedImage',
      image: EthLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: TonLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: BaseLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: SolanaLogo,
    },

    {
      name: ' ChainsSupportedImage',
      image: EthLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: TonLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: BaseLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: SolanaLogo,
    },

    {
      name: ' ChainsSupportedImage',
      image: EthLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: TonLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: BaseLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: SolanaLogo,
    },

    {
      name: ' ChainsSupportedImage',
      image: EthLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: TonLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: BaseLogo,
    },
    {
      name: ' ChainsSupportedImage',
      image: SolanaLogo,
    },

    {
      name: ' ChainsSupportedImage',
      image: EthLogo,
    },
  ];
  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1199px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '575px',
      numVisible: 2,
      numScroll: 1,
    },
  ];

  const productTemplate = (product) => {
    return (
      <div className=' text-center py-5  px-0'>
        <div className='mb-3 carouse___box'>
          <img
            src={product.image}
            alt={product.name}
            className='shadow-2 carousel___image '
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className='chains__supported__section '
      data-aos='fade-down'
      data-aos-duration='1000'
    >
      <div className='title text-center my-5 '>
        <h3 className='capitalize'> Chains supported </h3>
      </div>
      <Carousel
        value={products}
        numVisible={4}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        itemTemplate={productTemplate}
        showNavigators={false}
        autoplayInterval={4000}
        showIndicators={false}
        circular
        className='chain__card'
      />
    </div>
  );
};
export default ChainsSupported;
