import React from 'react';
import side1Image from '../assets/side1.png';
import side2Image from '../assets/side2.png';
import side3Image from '../assets/side3.png';
import side4Image from '../assets/side4.png';
import side5Image from '../assets/side5.png';
import w1 from '../assets/w1.png';
import w2 from '../assets/w2.png';
import w3 from '../assets/w3.png';
import w4 from '../assets/w4.png';
import '../styles/referral.css';
import oiImage from '../assets/oi.jpg';
import Index from './Index';
import JoinTapTrader from '../components/JoinTapTrader';

const Referral = () => {
  const icons = [
    {
      imgSrc: w1,
      description:
        'Your TapTrader banner or link is displayed on your website.',
    },
    {
      imgSrc: w2,
      description:
        'Visitors click on the banner or link.',
    },
    {
      imgSrc: w3,
      description:
        'Our system tracks the unique URL associated with your banner or link.',
    },
    {
      imgSrc: w4,
      description:
        'Visitors who click through make trades, and you benefit from their activity.',
    },
  ];
  
  const data = [
    {
      id: 1,
      imageSrc: side1Image,
      title: 'Instant Trade Execution',
      description:
        'TapTrader ensures lightning-fast trade execution across Ethereum, Solana, and Base, allowing you to secure the best market opportunities in real-time.',
    },
    {
      id: 2,
      imageSrc: side2Image,
      title: 'Advanced Sniping Tools',
      description:
        'Leverage TapTrader’s Token Sniper and Auto Sniper functionalities to capture the best entry points and maximize your profits with ease.',
    },
    {
      id: 3,
      imageSrc: side3Image,
      title: 'Low Transaction Fees',
      description:
        'Enjoy some of the lowest transaction fees in the market while ensuring secure and efficient portfolio management, perfect for active traders.',
    },
    {
      id: 4,
      imageSrc: side4Image,
      title: 'Secure Portfolio Management',
      description:
        'Manage your assets confidently with TapTrader’s secure portfolio tracking and real-time updates directly in Telegram and via browser.',
    },
    {
      id: 5,
      imageSrc: side5Image,
      title: 'User-Friendly Interface',
      description:
        'TapTrader’s intuitive interface is designed to make trading accessible for beginners and advanced users alike, offering a smooth and efficient experience.',
    },
  ];
  

  return (
    <section className='referral__section mb-7 mt-3 '>
      <div className='grid w-11 md:w-11 lg:w-11 xl:w-10 mx-auto '>
        <div className=' col-11 md:col-11 lg:col-6  xl:col-6'>
          <div className='title'>
            <h4 className='____h4'>
              {' '}
              <span className='h4___span'>@TheTapTraderBot </span>{' '}
            </h4>
          </div>

          <div className='sub____title'>
            <h2>Earn rewards by referring friends to TapTrader</h2>
          </div>
          <div className='pargraph mb-0'>
            <p className='referral__paragraph '>
            Trade faster with one-tap execution, manage your portfolio securely, and
            enjoy unparalleled results with TapTrader. Our high-speed, low-cost
            trading bot is designed to help you stay ahead of the curve, whether you’re
            a seasoned trader or just starting out.
            </p>
          </div>

          <div className='image my-3'>
            <img src={oiImage} alt='TheTaptrader' className='referral__image' />
          </div>

          <div className='paragraph'>
            <p className='referral__paragraph '>
            TapTrader delivers high-speed trade execution, accurate portfolio
            management, and low fees. With Token Sniper and Auto Sniper features,
            you can capture the best entry points in real-time and maximize your
            profits across Ethereum, Solana, and Base networks.
            </p>
          </div>
        </div>

        <div className=' col-11 md:col-11 lg:col-6  xl:col-5 xl:col-offset-1 mt-7'>
          {data.map((item) => (
            <div className='grid' key={Index}>
              <div className='col-3 md:col-2 lg:col-2 xl:col-2'>
                <div className='referral___icon'>
                  <img
                    className='referral__image'
                    src={item.imageSrc}
                    alt='sideImage'
                  />
                </div>
              </div>

              <div className='col-9 md:col-9 lg:col-9 xl:col-9'>
                <span className='font-bold'>{item.title}</span>
                <div className=''>
                  <p className='referral__paragraph'>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <section className='how__it__works__section my-7 w-11 md:w-11 lg:w-10 xl:w-10 mx-auto  mx-auto'>
        <div className='title text-center'>
          <h3>How It Works</h3>
        </div>
        <div className='grid'>
          {icons.map((icon, index) => (
            <div
              key={index}
              className=' col-11 md:col-6 lg:col-3 xl:col-3 text-center'
            >
              <div className='hitws__icon mb-1'>
                <img
                  src={icon.imgSrc}
                  alt={`work${index + 1}`}
                  className='w___image'
                />
              </div>

              <div className='paragraph'>
                <p>{icon.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <JoinTapTrader />
    </section>
  );
};

export default Referral;
