import React from 'react';
import { Menubar } from 'primereact/menubar';
import '../styles/AppHeader.css';
import TapTraderLogo from '../assets/tplogo.png';
import { Link } from 'react-router-dom';

export default function AppHeader() {
  const items = [
    {
      label: 'Home',
      url: '/',
    },

    {
      label: 'Referral',
      url: '/referral',
    },
    {
      label: 'Community',
      items: [
        {
          label: 'Twitter',
          icon: 'pi pi-twitter',
          command: () => {
            window.open('https://twitter.com', '_blank');
          },
        },
        {
          label: 'Telegram',
          icon: 'pi pi-telegram',
          command: () => {
            window.open('https://t.me/TheTapTraderBot', '_blank');
          },
        },
        {
          label: 'Discord',
          icon: 'pi pi-discord',
          command: () => {
            window.open('https://discord.com', '_blank');
          },
        },

        {
          label: 'Github',
          icon: 'pi pi-github',
          command: () => {
            window.open('https://github.com', '_blank');
          },
        },
      ],
    },
  ];

  const start = (
    <div className=' logo__style'>
      <Link to='/'>
        <img
          alt='logo'
          src={TapTraderLogo}
          className='mr-2 logo__image__style'
        />
      </Link>
    </div>
  );
  const end = (
    <div className='flex align-items-center gap-2'>
      <i
        className='pi pi-telegram pi-spin telegram__icon__style'
        onClick={() => window.open('https://t.me/TheTapTraderBot', '_blank')}
      ></i>
    </div>
  );
  return (
    <div className='w-full  md:w-11 lg:w-11 xl:w-10 mx-auto mt-4'>
      <Menubar
        model={items}
        start={start}
        end={end}
        className='border-none  menu__style'
      />
    </div>
  );
}
