import React from 'react';
import '../styles/PrivateKeyManagement.css';
import { Galleria } from 'primereact/galleria';
import TapTraderImage1 from '../assets/interface1.png';
import TapTraderImage2 from '../assets/interface2.png';
import TapTraderImage3 from '../assets/interface3.png';
import taptraderLogo from '../assets/taptrader__telegram__logo.jpg';

const PrivateKeyManagement = () => {
  const images = [
    {
      itemImageSrc: TapTraderImage1,

      alt: 'Description for Image 1',
    },
    {
      itemImageSrc: TapTraderImage2,

      alt: 'Description for Image 1',
    },
    {
      itemImageSrc: TapTraderImage3,

      alt: 'Description for Image 1',
    },
  ];

  const itemTemplate = (item) => {
    return (
      <div className='image___carousel__div'>
        <img
          className='carouse___image'
          src={item.itemImageSrc}
          alt={item.alt}
          loading='lazy'
        />
      </div>
    );
  };

  return (
    <div
      className='PrivateKeyManagement'
      data-aos='fade-down'
      data-aos-duration='1000'
    >
      <section className=' w-11 md:w-11 lg:w-11 xl:w-10 mx-auto '>
        <div className='title w-11 md:w-8 lg:w-7 xl:w-7 mx-auto my-8'>
          <h3 className='text-center'>
            Private Key Management Create & Export
          </h3>
        </div>

        <div className='grid'>
          <div className=' w-11 mx-auto sm:w-11 md:w-11 lg:w-6 xl:w-6 '>
            <p className='light-text'>
              Securely export and import your wallet private keys to manage your
              trading portfolio across different platforms.
            </p>

            <ul className='list-disc'>
              <li className='list__style'>Instant Notifications:</li>
              <li className='list__style'>Token Discovery</li>
              <li className='list__style'>Fail Guard </li>

              <li className='list__style'>Lucrative Referal rewards </li>
            </ul>

            <span>
              Start trading in no time with our easy-to-use interface and clear
              instructions
            </span>

            <section className='se'>
              <h5 className='h5__subtitle'>
                {' '}
                <img
                  src={taptraderLogo}
                  className='side____icon mr-2'
                  alt='logo'
                  loading='lazy'
                />
                Who We Are
              </h5>
              <p className='light-text'>
                At TapTrader, we empower traders with cutting-edge tools that
                simplify and enhance the trading experience across Ethereum,
                Solana, and Base networks. As the fastest Telegram-based trading
                bot, TapTrader offers instant trade execution and robust
                portfolio management. Whether you're a newcomer or a seasoned
                trader, our platform is designed to help you navigate the
                complexities of trading with ease.
              </p>
            </section>

            <section className='se'>
              <h5 className='h5__subtitle'>
                {' '}
                <img
                  src={taptraderLogo}
                  className='side____icon mr-2'
                  alt='logo'
                  loading='lazy'
                />
                Wealth
              </h5>
              <p className='light-text'>
                True wealth is not just about financial gains; it's about making
                informed, strategic decisions that create long-term value.
                TapTrader equips you with the tools you need to secure the best
                opportunities in token markets, enabling you to grow your
                portfolio with precision and efficiency.
              </p>
            </section>
          </div>

          <div className='w-11 sm:w-11 mx-auto  md:w-11 lg:w-6 xl:w-5 '>
            <div className=''>
              <Galleria
                value={images}
                className=''
                showThumbnails={false}
                showIndicators={false}
                item={itemTemplate}
                autoPlay
                circular
                orientation='vertical'
                transitionInterval={3000}
              />
            </div>

            <section className='se'>
              <h5 className='h5__subtitle'>
                {' '}
                <img
                  src={taptraderLogo}
                  className='side____icon mr-2'
                  alt='logo'
                  loading='lazy'
                />
                True Riches
              </h5>
              <p className='light-text'>
                True riches come from knowledge, speed, and security. With our
                high-speed sniping tools, you can capture the most profitable
                trades at the lowest market fees. TapTrader’s secure and
                accurate portfolio management ensures that your investments are
                protected while maximizing your potential for success.
              </p>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivateKeyManagement;
