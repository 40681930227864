import React from 'react';
import Faq from '../components/Faq';
import WhyTapTrader from '../components/WhyTapTrader';
import HeroPage from '../components/HeroPage';
import PrivateKeyManagement from '../components/PrivateKeyManagement';
import ChainsSupported from '../components/ChainsSupported';
import JoinTapTrader from '../components/JoinTapTrader';

const Index = () => {
  return (
    <section className='Index__section '>
      <HeroPage />
      <WhyTapTrader />
      <ChainsSupported />
      <PrivateKeyManagement />
      <Faq />
      <JoinTapTrader id='WhyTapTrader' />
    </section>
  );
};

export default Index;
