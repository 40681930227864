import React from 'react';
import '../styles/WhyTapTrader.css';

const WhyTapTrader = () => {
  const cardData = [
    {
      iconClass: 'pi pi-check',
      title: 'User Friendly Interface',
      description:
        "Navigate through TapTrader's intuitive Telegram interface with ease. From beginners to seasoned traders  designed to be accessible and efficient for all.",
    },
    {
      iconClass: 'pi pi-star',
      title: 'High Speed & Low Cost',
      description:
        'Stay ahead with our Sniper Tools, capturing the best entry points and token launches using Token Sniper and Auto Sniper for fast, low-fee transactions.',
    },
    {
      iconClass: 'pi pi-lock',
      title: 'Multichain Support',
      description:
        'Trade effortlessly across Ethereum, Solana, and Base. Diversify your portfolio and maximize liquidity with a single, powerful bot.',
    },
    {
      iconClass: 'pi pi-globe',
      title: 'MEV Protection',
      description:
        'Protect your trades from malicious MEV bots. Our private transactions feature keeps your trades safe from frontrunning and sandwich attacks.',
    },
  ];

  return (
    <section
      className='why__tap__trader__section '
      data-aos='fade-down'
      data-aos-duration='1000'
    >
      <div className='wrapper w-11 md:w-10 lg:w-11 xl:w-10 mx-auto'>
        <div className='title text-center mb-7'>
          <h3> Why TapTrader?</h3>
          <p className='light-text'>
            You can now view tap-trader-portfolio in the browser.You can now
            view tap-trader-portfolio in the browser
          </p>
        </div>

        <div className='grid'>
          {cardData.map((card, index) => (
            <div key={index} className='col-12 md:col-6 lg:col-3 xl:col-3'>
              <div className='card1'>
                <div className='icon'>
                  <i
                    className={`${card.iconClass} icon___style`}
                    style={{ fontSize: '1.3rem' }}
                  ></i>
                </div>

                <div className='sub__titl2 -mb-4'>
                  <h3 className='h3__style'>{card.title}</h3>
                </div>
                <div className='paragraph'>
                  <p>{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyTapTrader;
