import React from 'react';
import '../styles/Faq.css';
import FaqImage from '../assets/faq.png';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge';

const Faq = () => {
  const accordionData = [
    {
      header: 'What is TapTrader?',
      badge: '1',
      content: `TapTrader is the fastest Telegram-based trading bot, designed to provide seamless and efficient trade execution across Ethereum, Solana, and Base networks. It offers one-tap trade functionality, secure portfolio management, and powerful sniping tools for capturing the best entry points.`,
    },
    {
      header: 'How does TapTrader help in trading?',
      badge: '2',
      content: `TapTrader helps traders by offering instant trade execution, high-speed sniping tools, and secure portfolio management. With features like Token Sniper and Auto Sniper, users can capitalize on new token launches and secure trades with minimal transaction fees.`,
    },
    {
      header: 'Can I view my TapTrader portfolio outside of Telegram?',
      badge: '3',
      content: `Yes! You can now view your TapTrader portfolio directly in your browser, making it even more convenient to track and manage your investments.`,
    },
    {
      header: 'Is TapTrader user-friendly for beginners?',
      badge: '4',
      content: `Absolutely. TapTrader is designed with a user-friendly interface that’s intuitive and accessible for both beginners and experienced traders. Our goal is to make trading as easy and efficient as possible for all users.`,
    },
    {
      header: 'What makes TapTrader faster than other bots?',
      badge: '5',
      content: `TapTrader is built for speed. Our high-speed sniping tools ensure that trades are executed almost instantly. Combined with low transaction fees, it allows traders to stay ahead in competitive markets and capture the best opportunities.`,
    },
    {
      header: 'What are the fees like on TapTrader?',
      badge: '6',
      content: `TapTrader operates at one of the lowest transaction fees on the market. We aim to provide high-speed trade execution and secure portfolio management without breaking the bank.`,
    },
    {
      header: 'What networks does TapTrader support?',
      badge: '7',
      content: `TapTrader currently supports trading on Ethereum, Solana, and Base networks, offering users access to a wide variety of tokens across these blockchains.`,
    },

    {
      header: 'What are Token Sniper and Auto Sniper functionalities?',
      badge: '8',
      content: `Token Sniper and Auto Sniper are specialized tools within TapTrader that allow users to automatically snipe new token launches, securing the best entry points in a high-speed trading environment.`,
    },
    {
      header: 'How does TapTrader ensure security?',
      badge: '9',
      content: `TapTrader is committed to secure portfolio management. We utilize industry-best practices to safeguard your assets while ensuring accurate tracking and management of your investments.`,
    },
    {
      header: "What is TapTrader's mission?",
      badge: '10',
      content: `Our mission is to provide traders of all levels with access to high-speed, low-cost trading tools. We aim to democratize trading by making it simple, accessible, and secure, empowering users to grow their wealth in the fast-paced world of cryptocurrency.`,
    },
  ];
  return (
    <section
      className='faq__section '
      data-aos='fade-down'
      data-aos-duration='1000'
    >
      <div className='grid  w-11 md:w-10 lg:w-11 xl:w-10 mx-auto'>
        <div className='col-12 md:col-11 lg:col-5 xl:col-5'>
          <div className='title'>
            <h3 className='h2__style mb-0'>Frequently Asked Questions</h3>
          </div>

          <div className='paragraph'>
            <p className='light-text text-left'>
              Below you'll find answers to the most common questions about
              TapTrader, covering everything from its features to how it can
              help you succeed in the fast-paced world of cryptocurrency
              trading.
            </p>
          </div>

          <div className='faq__image___div'>
            <img
              src={FaqImage}
              alt='FaqsImage'
              loading='lazy'
              className='faq__image'
            />
          </div>
        </div>

        <div className='col-12 md:col-11 lg:col-7 xl:col-7 mt-6'>
          <div className=''>
            <Accordion className=''>
              {accordionData.map((tab, index) => (
                <AccordionTab
                  className=''
                  key={index}
                  header={
                    <span className='flex align-items-center gap-2 w-full'>
                      <span className='font-bold'>{tab.header}</span>
                      <Badge value={tab.badge} className='ml-auto' />
                    </span>
                  }
                >
                  <p className='m-0'>{tab.content}</p>
                </AccordionTab>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
