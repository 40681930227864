import React, { useEffect } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '/node_modules/primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import Index from './pages/Index';
import './App.css';
import Referral from './pages/Referral';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  return (
    <Router>
      <AppHeader />
      <Routes>
        <Route path='/' element={<Index />} />
        <Route path='/referral' element={<Referral />} />
      </Routes>
      <AppFooter />
    </Router>
  );
}

export default App;
